import React from "react"
import "../styles/app.scss"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import { Container, Section } from "bloomer"
import SEO from "../components/SEO/SEO"
import StatementBlock from "../components/Sanity/statementSerializer"

const GDPR = () => {
  const data = useStaticQuery(graphql`
    {
      sanityGdprStatement {
        _rawStatement
      }
    }
  `)
  return (
    <Layout>
      <SEO title="GDPR statement" />
      <Section>
        <Container>
          <StatementBlock blocks={data.sanityGdprStatement._rawStatement} />
        </Container>
      </Section>
    </Layout>
  )
}
export default GDPR
